$( document ).on('turbolinks:load', function() {
  var prevent_default = true

  // Use below code becasue the submit event is calling multiple time on form submit.
  // unbind the submit event
  $(document).off('submit', '#lrs_upload_form').on('submit', '#lrs_upload_form', function(e) {
    // Used this flag because we are using the same form submit event to submit the request. 
    if(prevent_default) {
      e.preventDefault();
      url = e.target.action + '/validate_file'
      $.ajax({
        type: 'POST',
        url: url,
        data: formData(),
        processData: false,
        contentType: false,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function(data) {
        // This code is used when there is no duplicate cable distribution for the job, 
        // So we are not showing the confirmation modal to user and manually submit the LRS file upload form.
        if (data.duplicate_cable_distribution != undefined && !data.duplicate_cable_distribution) {
          submitLRSForm();
        }
      });
    }
  })

  $(document).on('click', '.lrs_confirm_btn', function(e) {
    submitLRSForm();
    $(e.target).attr("disabled", true);
  })
  
  $(document).on('click', '.lrs_cancel_btn', function() {
    location.reload();
  })

  function submitLRSForm() {
    prevent_default = false;
    $("#lrs_upload_form").submit(); 
  }

  function formData() {
    fd = new FormData();
    file = $('#spreadsheet_file')[0].files[0];
    if(file != undefined) {
      fd.append("file", file);
    }
    return fd;
  }
})